import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import { Carousel } from "react-responsive-carousel"
import Layout from "components/layout"
import Seo from "components/seo"
import moment from "moment"
import * as FadeIn from "components/fadein"

const IndexPage = ({ data }) => {
  useEffect(() => {
    const openingCover = document.getElementById("opening-cover")
    setTimeout(function () {
      openingCover.classList.add("hide")
    }, 1000)
  }, [])

  return (
    <Layout>
      <div className="design-fix-2019-06">
        <div className="mv-wrap">
          <div id="opening-cover">
            <p className="logo">
              <img
                src="https://balukoweb.blob.core.windows.net/images/top/mv/first-view-logo.png"
                width={561}
                alt="Baluko Laundry Place"
                loading="lazy"
              />
            </p>
          </div>

          <div id="mv" className="pc">
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
            >
              <img
                src="https://balukoweb.blob.core.windows.net/images/top/mv/slide-img01.jpg"
                loading="lazy"
                width="100%"
                alt=""
              />
              <img
                src="https://balukoweb.blob.core.windows.net/images/top/mv/slide-img02.jpg"
                loading="lazy"
                width="100%"
                alt=""
              />
              <img
                src="https://balukoweb.blob.core.windows.net/images/top/mv/slide-img03.jpg"
                loading="lazy"
                width="100%"
                alt=""
              />
              <img
                src="https://balukoweb.blob.core.windows.net/images/top/mv/slide-img04.jpg"
                loading="lazy"
                width="100%"
                alt=""
              />
            </Carousel>
          </div>

          {/*/#mv*/}
          <div id="mv-sp" className="sp">
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              swipeable={false}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
            >
              <img
                src="https://balukoweb.blob.core.windows.net/images/top/mv/sp-slide-img01.jpg"
                loading="lazy"
                width="100%"
                alt=""
              />
              <img
                src="https://balukoweb.blob.core.windows.net/images/top/mv/sp-slide-img02.jpg"
                loading="lazy"
                width="100%"
                alt=""
              />
              <img
                src="https://balukoweb.blob.core.windows.net/images/top/mv/sp-slide-img03.jpg"
                loading="lazy"
                width="100%"
                alt=""
              />
              <img
                src="https://balukoweb.blob.core.windows.net/images/top/mv/sp-slide-img04.jpg"
                loading="lazy"
                width="100%"
                alt=""
              />
            </Carousel>
          </div>

          {/*/#mv-sp*/}
        </div>
        {/*/.mv-wrap*/}
        <div className="contents fw-b">
          <section className="top-lead">
            {/* <div className="wrap-1060"> */}
            <div className="lead-contents">
              <h2 className="title title-size-50 ls--1-5 pc-mb1">
                <span className="rub-pr">
                  今日も、<span className="e">Baluko</span>で。
                </span>
              </h2>
              <p className="lead lh-2-25 ls-0-3 pc-mt30">
                洗濯をする。汚れたものが、きれいになる。
                <br /> それだけのことで、すっと心が晴れやかになる。
                <br />
                ふかふかのタオルで幸せになったり、きれいなシャツがやる気をくれたりする。
                <br />
              </p>
              <p className="lead lh-2-25 ls-0-3 pc-mt30">
                <span className="e">Baluko</span>
                が目指す「洗濯」は、きれいに洗うだけではなく、
                <br className="pc" />
                そんな前向きな心の動きを生み出すもの。
              </p>
              <p className="lead lh-2-25 ls-0-3 pc-mt30">
                洗い上がりへの満足感はもちろん、
                <br /> 待っている時間すらも、心地のいいものに変えていきたい。
                <br /> 洗濯を、しなければならないものから、したくなるものへ。
                <br />
                <br className="sp" />
              </p>
              <p className="lead lh-2-25 ls-0-3 pc-mt30">
                洗い立ての空気に包まれる時間を、
                <span className="e">Baluko</span>で。
              </p>
            </div>
            {/* </div> */}
          </section>
          {/*/.top-lead*/}
          <FadeIn.Up>
            <section className="information">
              <div className="wrap-1060 tx-center">
                <h2 className="title title-size-40 ls--0-3 e">News</h2>
                <div className="news">
                  <ul className="news-list">
                    {data.allStrapiInformation.edges.map(({ node }) => (
                      <>
                        {node.category.uid !== "media" && (
                          <li key={node.id} className="list-item">
                            <Link
                              to={`/news/${node.slug}`}
                              className="news-item"
                            >
                              <p className="published-date e">
                                {moment(node.publishedAt).format(`YYYY.MM.DD`)}
                              </p>
                              <p className="news-title">{node.title}</p>
                            </Link>
                          </li>
                        )}
                        {node.category.uid === "media" && (
                          <>
                            {node.childStrapiInformationBodyTextnode !=
                              null && (
                              <li key={node.strapi_id} className="list-item">
                                <Link to={`/news/${node.slug}`}>
                                  <div className="news-item">
                                    <p className="published-date e">
                                      {moment(node.publishedAt).format(
                                        `YYYY.MM.DD`
                                      )}
                                    </p>
                                    <p className="news-title">{node.title}</p>
                                  </div>
                                </Link>
                              </li>
                            )}
                            {node.childStrapiInformationBodyTextnode === null &&
                              node.media_link && (
                                <li key={node.strapi_id} className="list-item">
                                  <a
                                    className="news-item"
                                    href={node.media_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <p className="published-date e">
                                      {moment(node.publishedAt).format(
                                        `YYYY.MM.DD`
                                      )}
                                    </p>
                                    <p className="news-title">{node.title}</p>
                                  </a>
                                </li>
                              )}
                            {node.childStrapiInformationBodyTextnode === null &&
                              node.media_link === null && (
                                <li key={node.strapi_id} className="list-item">
                                  <div className="news-item no-link-news">
                                    <p className="published-date e">
                                      {moment(node.publishedAt).format(
                                        `YYYY.MM.DD`
                                      )}
                                    </p>
                                    <p className="news-title">{node.title}</p>
                                  </div>
                                </li>
                              )}
                          </>
                        )}
                      </>
                    ))}
                  </ul>
                </div>

                <div className="link-wrap">
                  <Link to="/news" className="link-style-1 e">
                    Read More
                  </Link>
                </div>
              </div>
            </section>
          </FadeIn.Up>
          {/*/.information*/}
          <FadeIn.Up>
            <section className="service tx-center">
              <div className="title-area-style-1">
                <h2 className="title title-size-39 ls--0-3 e">Service</h2>
                <p className="sub-title fs-13 ls-0-3">バルコのサービス</p>
                <p className="title-lead ls--0-7 lh-1-8">
                  Baluko Laundry Placeは、
                  <br />
                  お客さまの毎日の「洗濯」という行為を
                  <br className="sp" />
                  楽しくすることを目的とした、
                  <br />
                  新しいスタイルのランドリーです。
                </p>
              </div>
              {/*/.title-area-style-1*/}
              <div className="wrap-max-1235">
                <ul className="lists effect">
                  <li>
                    <div className="icon">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-01.png"
                        width={67}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <p className="e en-title mh">
                      Machine
                      <br />
                      Washer
                    </p>
                    <h3 className="jp-title mh">
                      大容量の
                      <br />
                      洗濯機
                    </h3>
                  </li>
                  {/*/.li*/}
                  <li>
                    <div className="icon">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-02.png"
                        width={67}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <p className="e en-title mh">
                      Machine
                      <br />
                      Dryer
                    </p>
                    <h3 className="jp-title mh">
                      パワーのある
                      <br />
                      乾燥機
                    </h3>
                  </li>
                  {/*/.li*/}
                  <li>
                    <div className="icon">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-03.png"
                        width={67}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <p className="e en-title mh">
                      Detergent &amp;
                      <br />
                      Softener
                    </p>
                    <h3 className="jp-title mh">
                      オリジナルの
                      <br />
                      洗剤＆柔軟剤
                    </h3>
                  </li>
                  {/*/.li*/}
                  <li>
                    <div className="icon">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-04.png"
                        width={67}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <p className="e en-title mh">
                      Sneaker
                      <br />
                      Laundry
                    </p>
                    <h3 className="jp-title mh">
                      スニーカーも
                      <br />
                      洗えます
                    </h3>
                  </li>
                  {/*/.li*/}
                  <li>
                    <div className="icon">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-05.png"
                        width={67}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <p className="e en-title mh">IT Laundry</p>
                    <h3 className="jp-title mh">
                      マシンの混雑状況を
                      <br />
                      チェック
                    </h3>
                  </li>
                  {/*/.li*/}
                  <li>
                    <div className="icon">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/icon/service/top/app-icon.png"
                        width={67}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <p className="e en-title mh">Laundry App</p>
                    <h3 className="jp-title mh">
                      アプリで操作 &amp; <br />
                      WEB決済可能
                    </h3>
                  </li>
                  {/*/.li*/}
                  <li>
                    <div className="icon">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-07.png"
                        width={67}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <p className="e en-title mh">Cleaning</p>
                    <h3 className="jp-title mh">
                      クリーニング
                      <br />
                      サービス
                    </h3>
                  </li>
                  {/*/.li*/}
                  <li>
                    <div className="icon">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-08.png"
                        width={67}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <p className="e en-title mh">Wash and Fold</p>
                    <h3 className="jp-title mh">
                      洗濯代行
                      <br />
                      サービス
                    </h3>
                  </li>
                  {/*/.li*/}
                  <li>
                    <div className="icon">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-09.png"
                        width={67}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <p className="e en-title mh">Café</p>
                    <h3 className="jp-title mh">カフェ</h3>
                  </li>
                  {/*/.li*/}
                  <li>
                    <div className="icon">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-10.png"
                        width={67}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <p className="e en-title mh">wi-fi</p>
                    <h3 className="jp-title mh">
                      ワイファイ
                      <br />
                      環境完備
                    </h3>
                  </li>
                  {/*/.li*/}
                  <li>
                    <div className="icon">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-11.png"
                        width={67}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <p className="e en-title mh">Delivery</p>
                    <h3 className="jp-title mh">集荷配送</h3>
                  </li>
                  {/*/.li*/}
                  <li>
                    <div className="icon">
                      <img
                        className="lazy"
                        src="https://balukoweb.blob.core.windows.net/images/icon/service/black/i-18.png"
                        width={67}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <p className="e en-title mh">Pet Laundry</p>
                    <h3 className="jp-title mh">
                      ペット用品専用の
                      <br />
                      エリア
                    </h3>
                  </li>
                  {/*/.li*/}
                </ul>
                {/*/.lists*/}
              </div>
              <p className="note tx-right fw-n fs-10 pc">
                ※サービスによっては一部店舗に限られます。
              </p>
            </section>
          </FadeIn.Up>
          {/*/.service*/}
          <FadeIn.Up>
            <div className="">
              <section className="section-style-1 tx-center">
                <p className="number">
                  <img
                    className="lazy"
                    src="https://balukoweb.blob.core.windows.net/images/top/sef-laundry/number.png"
                    alt=""
                    height={250}
                    loading="lazy"
                  />
                </p>
                <div className="title-area-style-2 white">
                  <h2 className="title title-size-60 ls--0-3 e">
                    Self Laundry
                  </h2>
                  <p className="title-lead ls--0-7 lh-1-8">
                    日常のお洗濯はもちろん、羽毛ふとんやシューズまで。
                    <br /> 最新のマシンと、こだわりのオリジナル洗剤で
                    <br /> キレイに洗って心地よい仕上がりに。
                  </p>
                </div>
                <ul className="lists pc">
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-01.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-02.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-03.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-04.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-05.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-14.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-10.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-13.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                </ul>
                <ul className="lists sp">
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-01.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-02.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-03.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-04.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                </ul>
                <ul className="lists sp" style={{ marginTop: "15px" }}>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-05.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-14.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-10.png"
                      width={47}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-13.png"
                      width={47}
                      alt=""
                    />
                  </li>
                </ul>
                <div className="link-wrap">
                  <Link
                    className="link-style-1 e fs-20 white"
                    to="/self-laundry"
                  >
                    Read More
                  </Link>
                </div>
              </section>
              {/*/.section-style-1*/}
              <div className="images-movie-block pc-column-2">
                <div>
                  <img
                    className="lazy"
                    src="https://balukoweb.blob.core.windows.net/images/top/laundry-service/img-01.jpg"
                    alt=""
                    loading="lazy"
                    height="100%"
                    width="100%"
                  />
                </div>
                <div>
                  <img
                    className="lazy"
                    src="https://balukoweb.blob.core.windows.net/images/top/laundry-service/img-02.jpg"
                    alt=""
                    loading="lazy"
                    height="100%"
                    width="100%"
                  />
                </div>
                <div>
                  <img
                    className="lazy"
                    src="https://balukoweb.blob.core.windows.net/images/top/laundry-service/img-03.jpg"
                    alt=""
                    loading="lazy"
                    height="100%"
                  />
                </div>
                <div>
                  <img
                    className="lazy"
                    src="https://balukoweb.blob.core.windows.net/images/top/laundry-service/img-04.jpg"
                    alt=""
                    loading="lazy"
                    height="100%"
                    width="100%"
                  />
                </div>
              </div>
              {/*/.images-movie-block*/}
            </div>
          </FadeIn.Up>
          <FadeIn.Up>
            <div className="">
              <section className="section-style-1 tx-center">
                <p className="number">
                  <img
                    className="lazy"
                    src="https://balukoweb.blob.core.windows.net/images/top/laundry-service/number.png"
                    alt=""
                    height={250}
                  />
                </p>
                <div className="title-area-style-2 white">
                  <h2 className="title title-size-60 ls--0-3 e">
                    Laundry Service
                  </h2>
                  <p className="title-lead ls--0-7 lh-1-8">
                    衣類のケアはプロに任せてあなたの時間を有効活用。
                    <br /> 専門スタッフがご希望通りに仕上げる洗濯代行サービス＆
                    <br /> こだわり抜いた匠仕上げのクリーニングサービス。
                  </p>
                </div>
                <ul className="lists">
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-07.png"
                      width={47}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-08.png"
                      width={47}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-12.png"
                      width={47}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-11.png"
                      width={47}
                      alt=""
                    />
                  </li>
                </ul>
                <div className="link-wrap">
                  <a className="link-style-1 e fs-20 white" href="/laundry-out">
                    Read More
                  </a>
                </div>
              </section>
              {/*/.section-style-1*/}
              <div className="images-movie-block pc-column-2">
                <div>
                  <img
                    className="lazy"
                    src="https://balukoweb.blob.core.windows.net/images/top/laundry-out/img-01.jpg"
                    loading="lazy"
                    height="100%"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="lazy"
                    src="https://balukoweb.blob.core.windows.net/images/top/laundry-out/img-02.jpg"
                    loading="lazy"
                    height="100%"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="lazy"
                    src="https://balukoweb.blob.core.windows.net/images/top/laundry-out/img-03.jpg"
                    loading="lazy"
                    height="100%"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="lazy"
                    src="https://balukoweb.blob.core.windows.net/images/top/laundry-out/img-04.jpg"
                    loading="lazy"
                    height="100%"
                    alt=""
                  />
                </div>
              </div>
              {/*/.images-movie-block*/}
            </div>
          </FadeIn.Up>
          <FadeIn.Up>
            <div className="">
              <section className="section-style-1 tx-center">
                <p className="number">
                  <img
                    className="lazy"
                    src="https://balukoweb.blob.core.windows.net/images/top/cafe/number.png"
                    alt=""
                    height={250}
                  />
                </p>
                <div className="title-area-style-2 white">
                  <h2 className="title title-size-60 ls--0-3 e">Café</h2>
                  <p className="title-lead ls--0-7 lh-1-8">
                    お洗濯の待ち時間には、
                    <br className="sp" />
                    カフェでゆっくりコーヒーや軽食を。
                    <br />
                    インターネットもお楽しみいただけるよう、
                    <br />
                    Wi-Fi環境もご用意しています。
                  </p>
                </div>
                <ul className="lists">
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-09.png"
                      width={47}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/icon/service/white/i-10.png"
                      width={47}
                      alt=""
                    />
                  </li>
                </ul>
                <div className="link-wrap">
                  <Link className="link-style-1 e fs-20 white" to="/cafe">
                    Read More
                  </Link>
                </div>
              </section>
              {/*/.section-style-1*/}
              <div className="images-movie-block pc-column-2">
                <div>
                  <img
                    className="lazy"
                    src="https://balukoweb.blob.core.windows.net/images/top/cafe/img-01.jpg"
                    loading="lazy"
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="lazy"
                    src="https://balukoweb.blob.core.windows.net/images/top/cafe/img-02.jpg"
                    width="100%"
                    height="100%"
                    loading="lazy"
                    alt=""
                  />
                </div>
              </div>
              {/*/.images-movie-block*/}
            </div>
          </FadeIn.Up>

          {/*/.baluko-app TODO */}
          <FadeIn.Up>
            <section className="baluko-app tx-center">
              <div className="contents wrap-max-1216">
                <p className="left-text fs-30">Baluko App</p>
                <p className="right-text fs-30">Baluko App</p>
                <h2 className="title title-size-60 tx-center ls--3 lh-1-8 sp-ls-0 sp-lh-1-7">
                  Baluko App
                </h2>
                <p className="lead lh-1-91 sp-fz-12">
                  洗濯物をかかえて、
                  <br className="sp" />
                  スマホをポケットに入れランドリーへ。
                  <br />
                  100円玉がなくても、
                  <br className="sp" />
                  お財布を持たなくても困らない。
                  <br />
                  洗濯の間、お店を離れても、
                  <br className="sp" />
                  アプリが残り時間を教えてくれる。
                  <br />
                  Baluko Laundry Placeアプリで、
                  <br />
                  そんな新しいランドリー体験が
                  <br className="sp" />
                  始まります。
                </p>
                <ul className="lists">
                  <li className="pc">
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/top/app/app.png"
                      width={70}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                  <li className="sp">
                    <img
                      className="lazy"
                      src="https://balukoweb.blob.core.windows.net/images/top/app/app.png"
                      width={27}
                      loading="lazy"
                      alt=""
                    />
                  </li>
                </ul>
                <div className="center-image">
                  <img
                    className="lazy pc"
                    src="https://balukoweb.blob.core.windows.net/images/top/app/app-ill01.svg"
                    loading="lazy"
                    alt=""
                  />
                  <img
                    className="lazy sp"
                    src="https://balukoweb.blob.core.windows.net/images/top/app/app-ill01.svg"
                    width={122}
                    loading="lazy"
                    alt=""
                  />
                </div>
                <div className="link-wrap">
                  <a
                    className="link-style-1 e fs-20 white"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://app.baluko.jp/"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </section>
          </FadeIn.Up>
          {/*/.baluko-app*/}
        </div>
        {/*/.contents*/}
      </div>
    </Layout>
  )
}
export const Head = () => (
  <Seo
    title="洗濯ならバルコ ランドリープレイス"
    description="次世代型新感覚セルフランドリー。関東を中心に全国に店舗を展開。従来のコインランドリーのイメージを一掃する清潔でスタイリッシュな店内には最新のランドリー機器が並ぶ。高品質なクリーニング・洗濯代行・本格的なコーヒーを提供するカフェも併設。"
    topFlag={true}
  />
)

export default IndexPage
// memo    filter: { category: { uid: { eq: "news" } } } を削除
export const query = graphql`
  query ($limit: Int = 5, $skip: Int = 0) {
    allStrapiInformation(
      sort: { fields: [publishedAt], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      edges {
        node {
          strapi_id
          title
          publishedAt
          media_link
          slug
          category {
            uid
          }
          childStrapiInformationBodyTextnode {
            body
          }
        }
      }
    }
  }
`
